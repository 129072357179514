<template>
  <el-table
    :data="tableDataBasic"
    stripe
    class="basic-table"
  >
    <el-table-column :label="$t(`Spec.columName`)" width="150">
      <template slot-scope="scope">
        <span>{{ scope.row.name_zh }}</span><br>
        <span>{{ scope.row.name }}</span>
      </template>
    </el-table-column>
              
    <el-table-column :label="$t(`Spec.required`)" width="100">
      <template slot-scope="scope">
        <span
          :class="scope.row.required === true ?'text-danger':'text-info'"
        >
          {{ $t(`Spec.${scope.row.required}`) }}
        </span>
      </template>
    </el-table-column>
    <el-table-column :label="$t(`Spec.desciption`)">
      <template slot-scope="scope">
        <span>{{ scope.row.desciption }}</span>
      </template>
    </el-table-column>
    <el-table-column :label="$t(`Spec.Example`)">
      <template slot-scope="scope">
        <span>{{ scope.row.example }}</span>
      </template>
    </el-table-column>
    <template slot="empty">
      <el-empty :description="$t('general.NoData')" />
    </template>
  </el-table>
</template>

<script>

export default {
  name: 'BasicInfo',
  components: {
  },
  props: {
    tableDataBasic: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.basic-table{
  .el-form--inline .el-form-item{
    display: block;
    padding-left: 60px;
  }
  .el-table .el-table__expanded-cell{
    padding: 0;
  }
  .text-info{
    color: $text;
  }
  .text-danger{
    color: $danger;
  }
}
</style>
