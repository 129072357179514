<template>
  <div class="dataspec-dialog">
    <el-dialog 
      width="800PX" 
      top="10vh" 
      :visible.sync="dialogVisible"
      :before-close="handleClose"
    >
      <el-row>
        <el-col>
          <el-row
            :gutter="12"
            style="width: 90%; margin: 10px auto;"
          >
            <el-col :span="18" class="dialog-head">
              <span class="dialog-apimethod">post</span>
              <span>/data</span>
            </el-col>
            <el-col :span="6">
              <a :href="openApiLink" target="_blank">
                <div class="dialog-atag">前往OpenAPI</div>
              </a>
            </el-col>
            <el-col>
              <div class="code-block">
                <el-col :span="2" class="button-container">
                  <el-button class="btn-copy" circle size="medium" @click="copy">
                    <i class="el-icon-document-copy" />
                  </el-button>
                </el-col> 
                <pre>
                      <div id="apiBody">{{ apiBody }}</div>
                    </pre>
              </div>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import { SuccessMessage } from '@/Mixins'

export default {
  name: 'DialogApi',
  mixins: [SuccessMessage],
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    apiBody: {
      type: Object,
      default: () => ({})
    },
    params: {
      type: Object,
      default: () => ({})
    },
    openApiLink: {
      type: String,
      default: null
    }
  },
  methods: {
    copy() {
      const copyData = document.getElementById('apiBody').innerHTML
      
      if (navigator.clipboard) {
        navigator.clipboard.writeText(copyData)
      } else {
        const textArea = document.createElement('textarea')
        textArea.value = copyData
        document.body.appendChild(textArea)
        textArea.focus()
        textArea.select()

        return new Promise((res, rej) => {
          document.execCommand('copy') ? res() : rej
          textArea.remove()
        })
      }

      const message = this.$t('general.Copy')
      this.showSuccessMessage(message)
    },
    handleClose() {
      this.$emit('closeDialog')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.dataspec-dialog{
    text-align: left;
    .dialog-atag{
      padding-top: 5px;
        color:$primary;
        text-align: right;
      }
    .dialog-head{
      font-size: 1.125rem;
      .dialog-apimethod{
        min-width: 100px;
        height: 35px;
        background-color: #49cc90;
        color: #ffffff;
        padding: 5px 15px;
        margin-right: 10px;
        border-radius: 5%;
        line-height: 2;
      }
      
    }
    .code-block{
      padding: 0 20px;
      background-color: #111;
      color: #ffffff;
      position: relative;
      .button-container{
        position: absolute;
        top: 15px;
        right: 5px;
      }
      .el-button{
        background: transparent;
        color: #ffffff;
        border: none;
      }
    }
  }
</style>
