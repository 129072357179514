<template>
  <el-table
    :data="tableData"
    stripe
  >
    <el-table-column :label="$t(`Spec.item`)" width="300">
      <template slot-scope="scope">
        <span>{{ $t(`field.${scope.row.name}`) }}</span><br>
        <span>{{ scope.row.name }}</span>
      </template>
    </el-table-column>
    <el-table-column :label="$t(`Spec.unit`)" width="200">
      <template v-if="scope.row.unit" slot-scope="scope">
        {{ $t(`Unit.${scope.row.unit}`) }}
      </template>
    </el-table-column>
    <el-table-column :label="$t(`Spec.required`)">
      <template slot-scope="scope">
        <span
          :class="scope.row.required == 'true'?'text-danger':'text-info'"
        >
          {{ $t(`Spec.${scope.row.required}`) }}
        </span>
      </template>
    </el-table-column>
    <el-table-column
      prop="reasonalbe_data"
      :label="$t(`Spec.reasonalbe_data`)"
      width="300"
    />
    <el-table-column
      prop="example"
      :label="$t(`Spec.Example`)"
    />
    <template slot="empty">
      <el-empty :description="$t('general.NoData')" />
    </template>
  </el-table>
</template>

<script>

export default {
  name: 'DataList',
  components: {
  },
  props: {
    tableData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  }
}

</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.text-info{
  color: $text;
}
.text-danger{
  color: $danger;
}
</style>
